.customTable2{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.customTable2 .theader{
    background-color: #FAFAFA;
    border-bottom: 2px solid #cccccc;
    border-top: 1px solid #cccccc;
    padding-top: 10px;
    padding-bottom: 10px;
}

.customTable2 .tbody{
    margin-top: 10px;
}

.customTable2 .theader,
.customTable2 .tbody{
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
}

.customTable2 .theader .tr,
.customTable2 .tbody .tr{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.customTable2 .theader .tr .td,
.customTable2 .tbody .tr .td{
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.customTable2 .tbody .tr {
    margin-bottom: 5px;
    height: 32px;
}

.customTable2 .tbody.scroll{
    height: 300px;
    overflow: auto;
    justify-content: flex-start;
}
