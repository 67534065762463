.container{
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #F2F3F8;
}

.boxProto{
    width: 100%;
    height: 60px;
    border-radius: 5px;
    background: white;
    /* box-shadow: 1px 1px 8px #00000036; */
    margin-bottom: 10px;
    position: relative;
}

.boxProto:last-child{
    margin-bottom: 0;
}

.boxProto .title{
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 7px;
    border-radius: 5px;
    /* background-color: #44546a; */
    background-color: #44546ad1;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.boxProto .title img{
    width: 15px;
    margin-right: 3px;
}

.containerTitle .dataOption{
    height: 100%;
    position: absolute;
    right: 10px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerTitle .dataOption .option{
    margin-right: 10px;
    margin-left: 10px;
}

.containerTitle .dataOption .date{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerTitle .dataOption .value{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccccc;
    background: white;
    padding: 2px;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
}


.containerTitle .dataOption img{
    width: 18px;
    border-radius: 3px;
    background: white;
    border: 1px solid #a7a7a7;
    margin-right: 5px;
    cursor: pointer;
}