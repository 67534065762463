.dataUpload{
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    display: flex;
    justify-content: left;
    align-items: flex-start;
    padding: 10px;
}

.dataUpload .uploadMenu{
    width: 300px;
    height: calc(100% - 10px);
    box-shadow: 1px 1px 8px #00000021;
    background-color: white;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
}

.dataUpload .uploadMenu .title{
    height: 80px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5f5f5f;
}

.dataUpload .uploadMenu .title img{
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.dataUpload .uploadMenu .line{
    height: 1px;
    width: 80%;
    background-color: #cccccc;
}

.dataUpload .uploadMenu .menu{
    width: calc(60% - 30px);
    height: 40px;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 20px;
    margin-top: 15px;
    padding-left: 45px;

    background-image: url(../../assets/icon/arrow_b2.png);
    background-position: 12% 50%;
    background-size: 20px;
    background-repeat: no-repeat;
    cursor: pointer;
}


.dataUpload .uploadMenu .menu.active{
    background-image: url(../../assets/icon/arrow_w.png);
    background-color: #44546a;
    color: white;
}


.dataUpload .uploadMenu .menu img{
    width: 20px;
    margin-right: 5px;
}

.dataUpload .uploadHistory{
    width: calc(100% - 300px);
    margin-left: 10px;
    margin-top: 10px;
    height: calc(100% - 30px);
    box-shadow: 1px 1px 8px #00000021;
    background-color: white;
    border-radius: 5px;
    position: relative;
    /* padding-top: 215px; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 20px;
}

.dataUpload .uploadHistory .title{
    display: flex;
    justify-content: left;
    align-items: center;
    /* position: absolute; */
    /* left: 20px;
    top: 20px; */
    font-size: 25px;
    font-weight: bold;
    padding-left: 20px;
}

.dataUpload .uploadHistory .uploadBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #44546a;
    width: 100px;
    height: 30px;
    border-radius: 5px;
    position: absolute;
    right: 20px;
    top: 200px;
    color: white;
    cursor: pointer;
    font-size: 14px;
}

.dataUpload .uploadHistory .uploadBtn img{
    width: 20px;
    margin-right: 5px;
}

.table3{
    width: calc(100% - 0px);
    height: calc(100% - 40px);
    border-radius: 5px;
    border: 1px solid #cccccc;
    /* padding: 10px 0px 10px 0px; */
    /* margin: 10px; */
}

.table3 .tableHeader{
    font-size: 18px;
    font-weight: bold;
    background-color: #FAFAFA;
    color: #A8A8A8;
}

.table3 .tableHeader .tr,
.table3 .tableBody .tr{
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    height: 40.5px;
    border-bottom: 1px solid #cccccc73;
}

.table3 .tableHeader .tr .td,
.table3 .tableBody .tr .td{
    height: 100%;
    border-right: 1px solid #E6E6E6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table3 .tableHeader .tr .td.no,
.table3 .tableBody .tr .td.no{
    width: 5%;
}

.table3 .tableHeader .tr .td.createdAt,
.table3 .tableBody .tr .td.createdAt{
    width: 20%;
}

.table3 .tableHeader .tr .td.fileSize,
.table3 .tableBody .tr .td.fileSize{
    width: 20%;
}

.table3 .tableHeader .tr .td.filename,
.table3 .tableBody .tr .td.filename{
    width: 20%;
}

.table3 .tableHeader .tr .td.fileState,
.table3 .tableBody .tr .td.fileState{
    width: 13%;
}

.table3 .tableHeader .tr .td.download,
.table3 .tableBody .tr .td.download{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table3 .tableHeader .tr .td.btn,
.table3 .tableBody .tr .td.btn{
    width: calc(27% - 10px);
    justify-content: flex-start;
    padding-left: 10px;
}


.table3 .tableBody .tr{
    height: 50px;
}

.donwloadBtn{
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #44546a;
    width: 100px;
    height: 30px;
    border-radius: 15px;
    color: white;
}

.donwloadBtn img{
    width: 20px;
    height: 20px;
}

.dataUpload .info{
    /* position: absolute; */
    /* top: 62px; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.dataUpload .info .infoBox{
    width: 220px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #F5F6F8;
    border-radius: 5px;
    margin-right: 15px;
}

.dataUpload .info .infoBox .infoValue{
    font-size: 25px;
    font-weight: bold;
    margin-top: 15px;
}

.treeListContainer{
    height: calc(100% - 20px);
    padding: 10px 20px 20px 20px;
    width: calc(100% - 40px);
    font-family: 'NEO_R';
}

.dataUpload .uploadHistory .pagination{
    height: 30px;
    /* position: absolute; */
    right: 155px;
    top: 200px;
    font-size: 14px;
    display: flex;
    justify-content: end;
    align-items: center;
    width: calc(100% - 160px);
    /* height: 55px; */
    margin-top: 55px;
    /* margin-bottom: 20px; */
    padding-right: 160px;
}

.dataUpload .uploadHistory .pagination .optionTitle{
    margin-right: 5px;
    margin-left: 5px;
}

.dataUpload .uploadHistory .pagination .curPaging{
    margin-right: 5px;
    margin-left: 5px;
}

.dataUpload .uploadHistory .pagination .line{
    height: 66%;
    width: 1px;
    border-right: 1px solid #cccccc;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 5px;
}

/* css arrow */

.wrap {
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-left: 5px;
    cursor: pointer;
  }

  .wrap .arrow {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    overflow: hidden;
    vertical-align: middle;
  }

  .wrap .arrow:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height:10px;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
    margin-top: 2px;
    border-top: 1px solid #333;
    border-left: 1px solid #333;
  }
  .wrap .arrow.top:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }            
  .wrap .arrow.down:before {
    transform: translate(-50%, -50%) rotate(-135deg);
  }
  .wrap .arrow.left:before {
    transform: translate(-50%, -50%) rotate(315deg);
    top: 45%;
    left: 57%;
  }      
  .wrap .arrow.right:before {
    transform: translate(-50%, -50%) rotate(135deg);
    top: 47%;
    left: 44%;
  }

/*tgGrid*/
.dataUpload .tgrid-column-header-text-wrapper{
    padding: 0.5em 1em 0.5em 1em;
    width: calc(100% - 2em);
}

.dataUpload span.i-collapse, 
.dataUpload span.i-expand{
    border-width: 10px 0 10px 10px;
}

.dataUpload span.i-expand{
    transform: translateY(3px);
}

.dataUpload span.i-collapse{
    transform: rotate(45deg) translateY(3px);
}

.tgrid-data-cell{
    position: relative;
}

.tgrid-data-cell .state{
    position: absolute;
    left: 20px;
    top: 5px;
}

.stateBtn{
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 5px;
    width: 80px;
    font-size: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dataUploadPopup{
    font-family: 'NEO_R';
}

.dataUploadPopup .tabPanel{
    padding: 10px;
}

.dataUploadPopup .tabPanel .inputLine{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.dataUploadPopup .tabPanel .inputLine .inputTitle{
    width: 150px;
}

.dataUploadPopup .tabPanel .inputLine .inputValue{
    display: flex;
    justify-content: center;
    align-items: center;
}

.dataUploadPopup .tabPanel .inputLine .inputValue .valueName{
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-right: 5px;
    margin-left: 5px;
    padding: 2px;
}

.dataUploadPopup .tabPanel .inputLine .inputValue .valueName:first-child{
    margin-left: 0px;
}

.dataUploadPopup .tabPanel .inputLine .inputValue .range{
    width: 10px;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dataUploadPopup .tabPanel .devisionLine{
    width: 100%;
    height: 1px;
    background-color: #cccccc;
}

.dataUploadPopup .customTable2{
    margin-top: 10px;
}

.customTable2 .tbody .tr .td{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.customTable2 .theader .tr .td,
.customTable2 .tbody .tr .td{
    text-align: left;
}

.customTable2 .theader .tr .td.displayName,
.customTable2 .tbody .tr .td.displayName{
    width: 10%;
}
.customTable2 .theader .tr .td.kp,
.customTable2 .tbody .tr .td.kp{
    width: 10%; /* *4 */
}

.customTable2 .theader .tr .td.measureType,
.customTable2 .tbody .tr .td.measureType{
    width: 5%;
}

.customTable2 .theader .tr .td.date,
.customTable2 .tbody .tr .td.date{
    width: 15%; /* *2 */
}

.customTable2 .theader .tr .td.button,
.customTable2 .tbody .tr .td.button{
    width: 15%;
}

.customTable2 .theader .tr .td.button2,
.customTable2 .tbody .tr .td.button2{
    width: 20%;
}

/* sensor */
.customTable2 .theader .tr .td.track,
.customTable2 .tbody .tr .td.track{
    width: 8%;
}

.customTable2 .theader .tr .td.kp,
.customTable2 .tbody .tr .td.kp{
    width: 8%;
}

.customTable2 .theader .tr .td.sensorName,
.customTable2 .tbody .tr .td.sensorName{
    width: 8%;
}

.customTable2 .theader .tr .td.number,
.customTable2 .tbody .tr .td.number{
    width: 8%;
}

.customTable2 .theader .tr .td.del,
.customTable2 .tbody .tr .td.del{
    width: 2%;
}

.inputSearch{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.inputSearch .inputLine{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 10px;
}

.inputSearch .inputLine .inputTitle{
    background-color: #fafafa;
    border-radius: 5px 0px 0px 5px;
    border: 1px solid #d9d9d9;
    border-right: 0;
    padding: 7px 7px 7px 7px;
    font-size: 14px;
}

.inputSearch .inputLine .inputValue{
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputSearch .inputLine .range{
    margin-right: 5px;
    margin-left: 5px;
}

.treeListContainer .devisionLine{
    width: 100%;
    height: 1px;
    background-color: #cccccc;
}

.no-border-radius .ant-select-selector {
    border-radius: 0px 5px 5px 0px !important;
}

.inputLine .inputValue .checkboxContainer{
    border: 1px solid #cccccc;
    border-radius: 0px 5px 5px 0px;
    padding-top: 3.5px;
    padding-bottom: 3.5px;
    padding-left: 10px;
    padding-right: 5px;
}

.treeListContainer .dataList .pagination{
    margin-top: 0px;
    margin-bottom: 10px;
    padding-right: 0;
    width: 100%;
}

.treeListContainer .customTable2 .theader .tr .td.btn,
.treeListContainer .customTable2 .tbody .tr .td.btn{
    display: flex;
    justify-content: left;
    align-items: center;
}

.treeListContainer .customTable2 .tbody .tr .td.btn .stateBtn{
    margin-right: 5px;
}