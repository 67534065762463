.main2{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main2 .sideMenuContainer{
    background-color: white;
    height: calc(100% - 35px);
    width: 299px;
    border-right: 1px solid #cccccc;
    padding-top: 35px;
    background-color: #f1f8fd30;
}

.main2 .content{
    width: calc(100% - 300px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100%);
}

.main2 .topBar{
    position: absolute;
    top: 0;
    width: calc(100% - 300px);
    background: #44546A;
    height: 10px;
}