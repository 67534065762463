.modalButton {
    background-color: white;
    border: 1px solid #bfbfbf;
    border-radius: 5px;
    font-size: 12px;
    padding: 1px;
    padding-left: 9px;
    padding-right: 8px;
    margin-right: 5px;
    cursor: pointer;
    font-family:  'NEO_R';
    user-select: none;
}

.modalButton.highlight {
    background: #006bff;
    color: white;
}

.modalButton.highlight.orange {
    background-color: #ff8d00;
    color: white;
}
