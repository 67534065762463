.monitoringContainer{
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    /* display: flex;
    justify-content: center;
    align-items: center; */
    padding: 10px;
    flex-direction: column;
    position: relative;
    overflow: auto;
}

.monitoringContainer .trackBox{
    width: 300px;
    height: 100%;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #cccccc;
    box-shadow: 1px 1px 8px #00000012;
    overflow: hidden;
}

.monitoringContainer .trackBox .track{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.monitoringContainer .trackBox .track img{
    width: 100%;
}

.monitoringContainer .trackDetailBox{
    /* flex-grow: 1; */
    width: calc(100% - 0px);
    height: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
    overflow: auto;
}

.monitoringContainer .trackDetailBox .search{
    width: calc(100% - 2px);
    height: 90px;
    border-radius: 5px;
    /* border: 1px solid #cccccc; */
    /* box-shadow: 1px 1px 8px #00000012; */
    margin-bottom: 10px;
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
}

.monitoringContainer .trackDetailBox .search .optionBox{
    height: calc(100% - 2px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1px solid #cccccc;
    box-shadow: 1px 1px 8px #00000012;
    margin-right: 10px;
    border-radius: 5px;
    overflow: hidden;
    flex-direction: column;
}

.monitoringContainer .trackDetailBox .search .optionBox:last-child{
    margin-right: 0;
}

.monitoringContainer .trackDetailBox .search .distanceSearch{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.monitoringContainer .trackDetailBox .search .dateSearch{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding-left: 15px;
    margin-right: 15px;
}

.monitoringContainer .trackDetailBox .search .searchBtn{
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background: #759cce;
    position: absolute;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.monitoringContainer .trackDetailBox .search .searchBtn img{
    width: 65%;
}

.monitoringContainer .trackDetailBox .minimap{
    width: calc(100% - 2px);
    height: 200px;
    border-radius: 5px;
    border: 1px solid #cccccc;
    box-shadow: 1px 1px 8px #00000012;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
}

.monitoringContainer .trackDetailBox .trackDetail{
    width: calc(100% - 2px);
    height: 860px;
    border-radius: 5px;
    border: 1px solid #cccccc;
    box-shadow: 1px 1px 8px #00000012;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
}

.monitoringContainer .trackDetailBox .trackDataMap{
    width: calc(100% - 2px);
    height: 60px;
    border-radius: 5px;
    border: 1px solid #cccccc;
    box-shadow: 1px 1px 8px #00000012;
    /* margin-bottom: 10px; */
    position: relative;
}

.monitoringContainer .trackDetailBox .icon{
    width: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
}

.monitoringContainer .trackDetailBox .icon img{
    width: 100%;
}

.monitoringContainer .trackDetailBox input.local{
    border: none;
    border-bottom: 1px solid #cccccc;
    margin-right: 10px;
    margin-left: 10px;
    width: 70px;
    text-align: center;
    font-size: 17px;
    font-family: 'NEO_R';
}

.monitoringContainer .trackDetailBox .optionTitle{
    width: 90px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-right: 1px solid #cbcbcb91; */
    /* margin-right: 20px; */
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
}

.monitoringContainer .trackDetailBox .optionTitle .label{
    width: calc(100% - 15px);
    display: flex;
    justify-content: left;
    align-items: center;
    height: 23px;
    background-color: #F2F2F2;
    border-bottom: 1px solid #D9D9D9;
    /* padding: 4px; */
    /* border-radius: 8px; */
    padding-left: 15px;
    font-size: 14px;
}

.monitoringContainer .trackDetailBox .divided{
    margin-left: 10px;
    margin-right: 10px;
}

.monitoringContainer .trackDetailBox .boxTitle{
    display: flex;
    justify-content: left;
    align-items: center;
    height: 23px;
    position: absolute;
    background: #44546a;
    /* color: white; */
    /* left: 10px; */
    /* top: 10px; */
    /* padding: 5px; */
    /* border-radius: 5px; */
    /* opacity: 0.85; */
    width: calc( 100% - 15px);
    background-color: #F2F2F2;
    border-bottom: 1px solid #D9D9D9;
    font-size: 14px;
    padding-left: 15px;
}

/* #minimapCanvas{
    width: 100%;
    height: 100%;
} */

#trackDetailCanvas{
    width: 100%;
    height: 100%;
}

.w100p{
    width: 100%;
}

.trackBox .boxTitle{
    width: calc(100% - 15px);
    display: flex;
    justify-content: left;
    align-items: center;
    height: 24px;
    background-color: #F2F2F2;
    border-bottom: 1px solid #D9D9D9;
    /* padding: 4px; */
    /* border-radius: 8px; */
    padding-left: 15px;
    font-size: 14px;
}

/*====================*/

.monitoringContainer .trackContent{
    width: 100%;
    height: calc( 100% - 0px);
    position: relative;
}

.monitoringContainer .speed{
    position: relative;
    height: 150px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-top: 10px;
}

.monitoringContainer .speed img.speedDemo{
    height: 150px;
    width: 100%;
}

.monitoringContainer .track{
    position: relative;
    height: calc(100% - 4px);
    display: flex;
    justify-content: left;
    align-items: center;
}

.monitoringContainer .track img{
    height: 100%;
}

.monitoringContainer .track .kpMarker{
    position: absolute;
    height: 100%;
    width: 4px;
    background: red;
    left: 0px;
    cursor: ew-resize;
}

.monitoringContainer .guideLine {
    left: 50%;
    position: absolute;
    top: 95px;
    width: 1px;
    height: calc(100% - 100px);
    background-color: red;
    z-index: 1;
}
.monitoringContainer .guideLine .KP{
    left: 6px;
    top: 6px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #44546a;
    padding: 4px;
    border-radius: 5px;
    color: white;
}

.monitoringContainer .guideLine .KP img{
    width: 15px;
    margin-right: 3px;
    margin-left: 3px;
}

.monitoringContainer .searchBar{
    height: 80px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.monitoringContainer .searchBar .minimap{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1500px;
}

.monitoringContainer .searchBar .searchOption{
    margin-bottom: 0;
}

.monitoringContainer .searchBar .dataSearch{
    flex-grow: 1;
    width: auto;
    margin-left: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.monitoringContainer .searchBar .dataSearch .icon {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65px;
    background: aliceblue;
    border-radius: 100%;
    margin-right: 10px;
}

.monitoringContainer .searchBar .dataSearch .icon img{
    width: 60%;
}


.monitoringContainer .minimap .switch{
    width: 260px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    height: calc(100% - 20px);
    margin-top: 10px;
    margin-bottom: 10px;
}

.monitoringContainer .minimap .switch .switchBtn{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.monitoringContainer .minimap .minimapContainer{
    width: 100%;
    height: 100%;
}

.monitoringContainer .minimap .minimapContainer canvas{
    width: 100%;
    height: 100%;
}

.monitoringContainer .datafinder .dataList{
    width: calc(100% - 15px);
    height: 100%;
}

.monitoringContainer .datafinder .dataList .line .dataName{
    width: 120px;
}

.popupTitle{
    height: 40px;
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: 1px solid #cccccc;
    padding-left: 15px;
}

.popupTitle img{
    height: 85%;
    margin-right: 10px;
}

.throughput .componentBox {
    padding: 10px;
    width: calc(100% - 20px);
    height: calc( 100% - 25px - 20px);
}
.throughput .componentBox .curDate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    height: 100%;
}

.directBtn{
    position: absolute;
    right: 30px;
    bottom: 10px;
    /* background-color: #44546A; */
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
}

.directBtn img{
    width: 20px;
    height: 20px;
}




.monitoringContainer .contentBox{
    width: calc(100% - 2px);
    background-color: white;
    margin-bottom: 10px;
}

.minimap{
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% - 20px);
    margin-top: 10px;
}

.searchNavigate .componentBox{
    overflow: auto;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 15px;
    height: calc(100% - 55px);
    width: calc(100% - 30px);
}

.searchNavigate .dataOption{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    position: relative;
}

.searchNavigate .dataOption .title{
    margin-right: 15px;
    min-width: 50px;
}

.searchNavigate .dataOption .title.border{
    border: 1px solid #cccccc;
    padding: 5px;
    border-radius: 5px;
}

.searchNavigate .line{
    height: 90%;
    width: 1px;
    background-color: #cccccc;
    margin-left: 20px;
    margin-right: 20px;
}

.monitoringContainer .contentBox.wearContainer.hidden{
    min-height: 38px;
    height: 38px;
}

.monitoringContainer .contentBox.wearContainer{
    min-height: 500px;
    height: calc(100% - 190px - 238px);
}

.monitoringContainer .contentBox.wearContainer.hidden #trackDetailCanvas{
    opacity: 0;
}

.railMapContainer{
    overflow: auto;
    width: 90vw;
    height: 85vh;
}

.zoomImgContainer{
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
    display: flex;
    justify-content: left;
    align-items: flex-start;
}

.zoomImgContainer .kpMarker{
    position: absolute;
    height: 100%;
    width: 4px;
    background: red;
    left: 0px;
    cursor: ew-resize;
}

#viewMapScroll{
    position: relative;
}

#viewMapScroll .loading{
    position: absolute;
    top: 0px;
    border: 0;
    width: calc(100%);
    height: calc(100%);
    background: #00000094;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: white;
    z-index: 1;
    opacity: 0;
    animation: fadeIn 2s forwards;
}

#viewMapScroll .loading img{
    height: 40px;
    margin-right: 25px;
}

.monitoringContainer .containerTitle .selectKPInfo{
    left: 80px;
    position: absolute;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background: white;
    font-size: 13px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.monitoringContainer .containerTitle .selectKPInfo .closestIcon{
    width: 14px;
    height: 5px;
    border-radius: 5px;
    margin-left: 17px;
}

.searchNavigate .dataOption.linear{
    width: 190px;
}
