.userInfoBox{
    background-color: #44546A;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    color: white;
}

.userInfoBox .userPicture{
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.userInfoBox .userPicture img{
    width: 100%;
}

.userInfoBox .userDetail{
    margin-right: 20px;
    font-size: 10px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.userInfoBox .userDetail .userName{
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 16px;
    margin-bottom: -2px;
    font-weight: bold;
}
.userInfoBox .userDetail .lastLogin{
    display: flex;
    justify-content: left;
    align-items: center;
}