.wear3DContainer {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.wear3DChekboxContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: 25px;
}

.wear3DRangeContainer {
    width: 100%;
    height: 50px;
}

.Wear3DChartContainer {
    width: 100%;
    height: calc(100% - 75px);
    position: relative;
}

.Wear3DChartContainer .legend{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100px;
    height: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid #cccccc;
    background: white;
    font-size: 11px;
}

.Wear3DChartContainer .legend .line{
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Wear3DChartContainer .legend .line .colorBar{
    width: 21px;
    height: 3px;
    margin-right: 5px;
}

.Wear3DChartContainer .legend .line .colorBar.red{
    background-color: red;
}

.Wear3DChartContainer .legend .line .colorBar.blue{
    background-color: blue;
}

.Wear3DChartContainer .legend .line .colorBar.yellow{
    background-color: yellow;
}

.Wear3DChartContainer .legend .line .colorBar.green{
    background-color: green;
}

.wear3DContainer .checkboxContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 15px;
    width: 15%;
    margin-top: 10px;
}