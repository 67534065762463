.trackDeviation{
    width: 100%;
    height: 100%;
}

.trackDeviation .componentBox{
    padding: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 25px - 20px);
}


.trackDeviation .componentBox .position{
    width: 300px;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-right: 5px;
    border-radius: 5px;
    border: 1px solid #cccccc;
}


.trackDeviation .contentBoxGroup{
    width: 100%;
    height: 238px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid #757575;
    border-radius: 5px;
    overflow: hidden;
    font-size: 13px;
}

.table .tableHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #D6D6D6;
    border-bottom: 1px solid #D6D6D6;
}

.table .tableHeader .tr,
.table .tableBody .tr{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 24px;
}

.table2 .tableHeader .tr,
.table2 .tableBody .tr{
    height: 32px;
}

.table .tableBody{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.table .tableHeader .tr .td,
.table .tableBody .tr .td{
    height: 100%;
    border-right: 0.5px solid #cccccc;
    border-bottom: 0.5px solid #cccccc;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.table .tableHeader .tr .td.detail,
.table .tableBody .tr .td.detail{
    width: 20%;
}

.table .tableHeader .tr .td.point,
.table .tableBody .tr .td.point{
    width: 10%;
}

.table .tableHeader .tr .td.detail2,
.table .tableBody .tr .td.detail2{
    width: 110px;
}

.table .tableHeader .tr .td.point2,
.table .tableBody .tr .td.point2{
    width: 50px;
}

.table .tableHeader .tr .td.colspan2{
    border-bottom: 0;
}
.table .tableHeader .tr .td.rowspan2{
    border-right: 0;
}

.table .tableHeader .tr .td .colspan2{
    width: 100%;
    height: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
}

.table .tableHeader .tr .td .rowspan2{
    width: 200%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
}

.trackDeviation .optionBox .optionValue{
    width: calc(100% - 55px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding-left: 10px;
    padding-right: 10px;
}

.trackGeometryMeasurement{
    width: 100%;
    height: 100%;
}

.trackGeometryMeasurement .table .tableHeader .tr, 
.trackGeometryMeasurement .table .tableBody .tr{
    height: 100%;
    justify-content: left;
}

.trackGeometryMeasurement .table .tableBody .tr:last-child,
.trackGeometryMeasurement .table .tableBody .tr:last-child .td{
    border-bottom: none;
}

.trackGeometryMeasurement .table4 .tableHeader .tr, 
.trackGeometryMeasurement .table4 .tableBody .tr{
    height: 22px;
}

.trackGeometryMeasurement .selectPoints{
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.trackGeometryMeasurement .selectPoints .point{
    font-size: 11px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background: white;
    padding: 4px;
    padding-left: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trackGeometryMeasurement .selectPoints .point img{
    width: 12px;
    height: 11px;
    margin-left: 5px;
    cursor: pointer;
}

.trackGeometryMeasurement .searchNavigate{
    position: relative;
}


.trackGeometryMeasurement .searchNavigate .loading{
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #00000038;
    z-index: 10;
    border-radius: 3px;
}

.trackGeometryMeasurement .contentBox .loading{
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #00000094;
    z-index: 10;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    animation: fadeIn 2s forwards;
    opacity: 0;
}

.trackGeometryMeasurement .contentBox .loading img{
    height: 40px;
    margin-right: 25px;
}


.trackGeometryMeasurement .containerTitle .legend{
    position: absolute;
    width: 200px;
    height: 100%;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trackGeometryMeasurement .containerTitle .legend .option{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 12px;
}

.trackGeometryMeasurement .containerTitle .legend .option .circle{
    background-color: black;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    border: 1px solid black;
    margin-right: 5px;
}

.trackGeometryMeasurement .containerTitle .legend .option .circle.yellow{
    background-color: #FFFF00;
}

.trackGeometryMeasurement .containerTitle .legend .option .circle.blue{
    background-color: #0000FF;
}

.trackGeometryMeasurement .containerTitle .blue .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0000FF; /* 새 배경 색상 */
    border-color: #0000FF; /* 새 테두리 색상 */
}

.trackGeometryMeasurement .containerTitle .yellow .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #FFFF00; /* 새 배경 색상 */
    border-color: #FFFF00; /* 새 테두리 색상 */
}

.trackGeometryMeasurement .containerTitle .yellow .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: black; /* 체크 표시의 색상을 바꿈 */
}

.trackGeometryMeasurement .table .tableHeader .tr .td.rowspan2{
    width: 51px;
}