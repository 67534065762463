
.monitoringContainer .datafinder .dataList .line{
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3000px;
}

.monitoringContainer .datafinder{
    position: relative;
    height: 400px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-top: 10px;
    overflow: auto;
}

.monitoringContainer .datafinder .dataList .line .dataBar .kp{
    width: 50px;
    font-size: 11px;
    height: 15px;
    position: absolute;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid #44546a;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5px;
    border: 1px solid #bdbdbd;
    color: #44546a;
    user-select: none;
}

.monitoringContainer .datafinder .dataList .line .dataBar{
    flex-grow: 1;
    height: 100%;
    border-bottom: 1px solid #cccccc;
    position: relative;
}

.monitoringContainer .datafinder .dataList .line .dataBar.kp{
    border-bottom: 2px dashed black;
}

.tabPanel{
    padding-bottom: 50px;
}

.paut .componentBox {
    padding: 10px;
    width: calc(100% - 20px);
    height: calc( 100% - 25px - 20px);
}
.paut .componentBox .curDate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    height: 100%;
}

.paut.componentBox .leftBtn,
.paut.componentBox .rightBtn{
    width: 30px;
    height: 30px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.paut.componentBox .leftBtn{
    position: absolute;
    left: 10px;
    top: 50%;
    background-image: url(../../assets/icon/dataexist/left.png);
}

.paut.componentBox .rightBtn{
    position: absolute;
    right: 10px;
    top: 50%;
    background-image: url(../../assets/icon/dataexist/right.png);
}

.monitoringContainer .datafinder .dataList .line .dataBar .detailBtn{
    width: 3px;
    height: 16px;
    font-size: 11px;
    position: absolute;
    padding: 2px;
    border-radius: 5px;
    background-color: #A9FF00;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5px;
    /* border: 1px solid #bdbdbd; */
    color: white;
    cursor: pointer;
    user-select: none;
    border: 1px solid #A9FF00;
    transition: 0.2s;
}

.monitoringContainer .datafinder .dataList .line .dataBar .detailBtn:hover{
    background-color: #00A2E8;
    border: 1px solid #bdbdbd;
    z-index: 1;
}

.monitoringContainer .datafinder .dataList .line .dataBar .detailBtn .tooltip{
    background: white;
    width: 155px;
    height: 25px;
    position: absolute;
    top: -61px;
    border-radius: 5px;
    border: 1px solid #cccccc;
    box-shadow: 3px 3px 7px #0000000d;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 14px 10px 10px 10px;
    display: none;
    z-index: 1;
}

.monitoringContainer .datafinder .dataList .line .dataBar .detailBtn.onTooltip .tooltip{
    display: flex;
}

.monitoringContainer .datafinder .dataList .line .dataBar .detailBtn .tooltip .tooltipLine{
    color: black;
    margin-top: 2.5px;
    margin-bottom: 2.55px;
}

.monitoringContainer .datafinder .dataList .line .dataBar.railtwists .detailBtn .tooltip{
    height: 51px;
    top: -89px;
}

.monitoringContainer .datafinder .dataList .line .dataBar.railwears .detailBtn .tooltip{
    height: 51px;
    top: 30px;
}

.monitoringContainer .datafinder .dataList .line .dataBar.accumulateWeights .detailBtn .tooltip{
    height: 51px;
    top: 30px;
    width: 155px;
}

.monitoringContainer .datafinder .dataList .line .dataBar.paut .detailBtn .tooltip{
    height: 40px;
    top: -75px;
}

.monitoringContainer .datafinder .dataList .line .dataBar.railstraights .detailBtn .tooltip{
    height: 40px;
    top: -75px;
}

.monitoringContainer .datafinder .dataList .line .dataBar.dataBarRailFile .detailBtn .tooltip{
    height: 40px;
    top: -75px;
}

.monitoringContainer .datafinder .dataList .line .dataBar.railRoughnesses .detailBtn .tooltip{
    height: 51px;
    top: -89px;
}

.monitoringContainer .datafinder .dataList .line .dataBar.railbehaviors .detailBtn .tooltip{
    height: 51px;
    top: -89px;
}

.tabPanel .dataOptionBox{
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100%;
}

.tabPanel .dataOptionBox .dataOption{
    position: static;
}

.tabPanel .profileData .table{
    justify-content: flex-start;
}

.tabPanel .profileData{
    margin-bottom: 10px;
    height: 293px;
    width: calc(100% - 14px);
}

.tabPanel .profileData .table .tableHeader{
    overflow-y: auto;
}

.tabPanel .profileData .table .tableHeader::-webkit-scrollbar-thumb,
.tabPanel .profileData .table .tableHeader::-webkit-scrollbar-track {
    background-color: #00000000;
}

.tabPanel .profileData .table .tableBody{
    overflow-y: scroll;
}