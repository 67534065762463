.railRoughness .componentBox .radioButtons {
    width: 200px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.railRoughness .componentBox .distanceSearch {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    height: 100%;
}

.railRoughness .componentBox .distanceSearch input.local {
    border: none;
    border-bottom: 1px solid #cccccc;
    margin-right: 10px;
    margin-left: 10px;
    width: 70px;
    text-align: center;
    font-size: 17px;
    font-family: 'NEO_R';
}

.table .tableHeader .tr .td.no, 
.table .tableBody .tr .td.no {
    width: 20%;
}

.table .tableHeader .tr .td.regDate, 
.table .tableBody .tr .td.regDate {
    width: 20%;
}

.table .tableHeader .tr .td.track, 
.table .tableBody .tr .td.track {
    width: 20%;
}

.table .tableHeader .tr .td.rail, 
.table .tableBody .tr .td.rail {
    width: 20%;
}

.table .tableHeader .tr .td.station, 
.table .tableBody .tr .td.station {
    width: 20%;
}

.table .tableHeader .tr .td.weld, 
.table .tableBody .tr .td.weld {
    width: 20%;
}

.table .tableHeader .tr .td.viewBtn, 
.table .tableBody .tr .td.viewBtn {
    width: 20%;
}

.table .tableBody .tr .td.viewBtn .viewBtn{
    width: 20px;
    height: 20px;
    background-color: #cccccc;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 1px 1px 8px 1px #0000001c;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.table .tableBody .tr .td.viewBtn .viewBtn img{
    width: 100%;
}

.railRoughness .table .tableBody .tr{
    height: 43px;
}

.report{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pdf-container {
    display: flex;
    overflow-x: auto;  /* 필요하다면 가로 스크롤 추가 */
  }
  
  .pdf-item {
    margin-right: 20px;  /* PDF 파일들 사이의 간격 조절 */
  }