.main{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.main .titleContainer{
    background-color: black;
    height: 55px;
    width: 100%;
}

.main .footer{
    height: 15px;
    width: 100%;
    background-color: #44546A;
    color: white;
    line-height: 30px;
}

.main .content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 70px);
}

.main .contentContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.main .contentContainer .sideContainer{
    width: 300px;
    height: 100%;
}