.loginConatiner{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
}

.loginConatiner .logo{
    width: 360px;
    margin-bottom: 25px;
}
.loginConatiner .logo img{
    width: 100%;
}
.containerBackground{
    background: #F8F8FA;
    width: 350px;
    height: 283px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 1px 8px 0px #00000024;
    margin-bottom: 50px;
}

#loginContainer {
    width: 300px;
    height: 233px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#greetings {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#login {
    width: calc(100% - 60px);
    height: 250px;
    background-color: white;
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
}

#login .line {
    width: 100%;
    margin: 7px 0px;
}

.subject {
    text-align: left;
    color: #6D6E8D;
    font-weight: 600;
    font-size: 12px;
}

.line.subject {
    margin-top: 15px;
    margin-bottom: 0px;
}

.loginInput {
    width: calc(100% - 20px);
    border: 1px solid #DEE2E6;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
}

.loginButton {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #626ED4;
    color: white;
    margin: 0;
    padding: 0.5rem 1rem;
    width: 100px;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.flexEnd {
    display: flex;
    justify-content: space-between;
}

#greetingsMain {
    color: white;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    letter-spacing: 0.8px;
}

#greetingsSub {
    color: #B5B7E7;
    font-size: 12px;
}

.remeberMe{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: bold;
}