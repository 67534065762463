.trackMapContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    overflow: auto;
}

.trackMapContainer img{
    user-select: none;
}