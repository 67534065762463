.modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
}

.modal button {
    outline: none;
    cursor: pointer;
    border: 0;
}

.modal>section {
    width: 90%;
    max-width: 550px;
    margin: 0 auto;
    background-color: #fff;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: modal-show 0.3s;
    overflow: hidden;
    border-radius: 5px;
}

.modal>section>header {
    position: relative;
    padding: 0px 64px 0px 16px;
    background-color: #f1f1f1;
    font-weight: 700;
    height: 23px;
    line-height: 25px;
    text-align: left;
    padding-top: 2px;
}

.modal>section>header button {
    position: absolute;
    top: 0px;
    right: 5px;
    width: 30px;
    font-size: 21px;
    font-weight: 700;
    text-align: center;
    color: #999;
    background-color: transparent;
}

.modal>section>main {
    padding: 16px;
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    min-height: 500px;
}

.modal>section>footer {
    /*padding: 12px 16px;*/
    text-align: right;
}

.modal>section>footer button {
    padding: 6px 12px;
    color: #fff;
    background-color: #6c757d;
    border-radius: 5px;
    font-size: 13px;
}

.modal.openModal {
    display: flex;
    align-items: center;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: modal-bg-show 0.3s;
}

@keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }

    to {
        opacity: 1;
        margin-top: 0;
    }
}

@keyframes modal-bg-show {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}