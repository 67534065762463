.wearMaintenance{
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
}

.railStatusContainer{
    height: 135px;
    width: calc(100% - 0px); 
    position: relative;
}

.pointContainer{
    width: 100%;
    /* height: 100%; */
    height: 165px;
}

.containerTitle{
    background-color: #F2F2F2;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    height: 23px;
    width: calc(100% - 15px);
    font-size: 14px;
    padding-top: 2px;
    position: relative;
}

.componentBox{
    height: calc(100% - 25px);
    width: 100%;
    background-color: white;
}

.contentBox {
    width: calc(100% - 0px);
    /* margin-left: 20px;
    margin-right: 20px; */
    border-radius: 2px;
    border: 1px solid #cccccc;
    overflow: hidden;
    box-shadow: 1px 1px 8px #cccccc5c;
}

.wearMaintenance .contentBox.searchContainer{
    width: 350px;
    height: calc(100% - 1px);
    margin-right: 10px;
}

.flexDirectionColumn {
    flex-direction: column;
}

.flex {
    display: flex;
}

.mr15{
    margin-right: 15px;
}

.mr10{
    margin-right: 10px;
}
  
.margin10 {
    margin: 10px;
}

.textBold {
    font-weight: bold;
}

.bothEnds {
    justify-content: space-between !important;
}

.sliderContainer {
    width: 100%;
}

.searchButton {
    background-color: white;
    border: 1px solid #bfbfbf;
    border-radius: 5px;
    font-size: 14px;
    padding: 1px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    font-family: 'NEO_R';
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
}

.mamoGraphContainer{
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 335px;
    height: calc(100% - 177px);
}

.graphGroup{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
}

.graphGroup .linearContainer{
    width: 50%;
    height: 100%;
}
.graphGroup .speedContainer{
    width: 50%;
    height: 100%;
}
.graphGroup .speedContainer .componentBox{
    margin-right: 5px;
    width: calc(100% - 5px);
    overflow: hidden;
    padding-top: 5px;
    padding-bottom: 5px;
    height: calc(100% - 35px);
}

.graphGroup .speedContainer .componentBox img{
    height: 100%;
}

.linearContainer .containerTitle.tab{
    padding-left: 5px;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.linearContainer .containerTitle .tab{
    border: 1px solid #bfbfbf;
    border-radius: 5px;
    font-size: 12px;
    padding: 1px;
    padding-left: 9px;
    padding-right: 8px;
    margin-right: 5px;
    cursor: pointer;
}

.linearContainer .containerTitle .tab.select{
    background-color: white;
}

.graphSection{
    width: 100%;
    /* height: 655px; */
    height: calc(100% - 177px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.graphSection .leftContainer{
    width: 30%;
    height: 100%;
    margin-right: 10px;
}

.graphSection .rightContainer{
    width: calc(100% - 360px);
    height: 100%;
}

.wearMaintenance .componentBox .radioButtons {
    width: 200px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccccc;
    margin-right: 5px;
    border-radius: 5px;
}

.wearMaintenance .componentBox .distanceSearch {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    height: 100%;
}

.wearMaintenance .componentBox .distanceSearch input.local {
    border: none;
    border-bottom: 1px solid #cccccc;
    margin-right: 10px;
    margin-left: 10px;
    width: 70px;
    text-align: center;
    font-size: 17px;
    font-family: 'NEO_R';
}

.wearMaintenance .componentBox .position {
    width: 300px;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-right: 5px;
    border-radius: 5px;
    border: 1px solid #cccccc;
    padding-right: 5px;
}

.wearMaintenance .contentBox{
    width: calc(100% - 2px);
}

.popupContent .componentBox .curDate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    height: 100%;
}

.wearMaintenance .table .tableHeader .tr .td,
.wearMaintenance .table .tableBody .tr .td{
    width: 100%;
}

.wearMaintenance #trackDetailCanvas{
    border-radius: 5px;
    border: 1px solid #cccccc;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
}

.wearMaintenance #trackDetailContainer{
    padding: 5px;
    height: calc(100% - 15px);
    width: calc(100% - 10px);
}

.speedContainer .componentBox{
    overflow: hidden;
    padding: 5px 5px 5px 5px;
    height: calc(100% - 35px);
    width: calc(100% - 10px);
}

.speedContainer .demoImgContainer{
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid #cccccc;
    display: flex;
    justify-content: left;
    align-items: center;
}

.speedContainer .demoImgContainer img{
    height: 90%;
}

.wearMaintenance .componentBox.separationBox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100% - 25px);
}

.searchContainer .componentBox .selectBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.searchContainer .componentBox .searchOption{
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 15px;
    margin-top: 10px;
}

.searchContainer .componentBox .searchOption:first-child{
    margin-top: 0;
}

.searchContainer .componentBox .searchOption .title{
    font-size: 13px;
}

.searchContainer .componentBox .searchOption .valueBox{
    align-items: center;
}

.searchContainer .componentBox .searchOption .dataText{
    font-size: 13px;
    font-weight: bold;
}

.wearMaintenance .contentBox .linearContainer {
    margin-bottom: 10px;
    height: calc(100% - 287px);
}

.wearMaintenance .dataContainer{
    width: 100%;
    height: calc(100% - 135px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.wearMaintenance .dataContainer .sideMenu{
    width: 238px;
    height: calc(100% - 2px);
    margin-right: 10px;
    border-radius: 2px;
    border: 1px solid #cccccc;
    box-shadow: 1px 1px 8px #cccccc5c;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff;
}

.wearMaintenance .dataContainer .sideMenu .title{
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: black;
    background-color: #f2f2f2;
    width: 100%;
    border-bottom: 1px solid #cccccc;
}

.wearMaintenance .dataContainer .sideMenu .title img{
    width: 30px;
    height: 30px;
    margin-right: 8px;
}

.wearMaintenance .dataContainer .sideMenu .line{
    height: 1px;
    width: 80%;
    background-color: #cccccc;
}

.wearMaintenance .dataContainer .sideMenu .menu{
    height: 40px;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 15px;
    padding-left: 45px;
    background-position: 8% 50%;
    background-size: 20px;
    background-repeat: no-repeat;
    cursor: pointer;
    width: calc(100% - 80px);
    color: #909aa9;
    border-radius: 5px;
}

.wearMaintenance .dataContainer .sideMenu .menu.active{
    background-image: url(../../assets/icon/arrow_w.png);
    background-color: #13818db8;
    color: white;
    font-weight: bold;
}

.wearMaintenance .minimapContainer{
    width: 100%;
    height: 100%;
}

.popupContent .table .tableHeader .tr .td,
.popupContent .table .tableBody .tr .td {
    width: 100%;
}

.popupContent .table .tableHeader .tr .td.driving,
.popupContent .table .tableBody .tr .td.driving {
    width: 8%;
}

.popupContent .table .tableHeader .tr .td.kp,
.popupContent .table .tableBody .tr .td.kp {
    width: 6%;
}

.popupContent .table .tableHeader .tr .td.rail,
.popupContent .table .tableBody .tr .td.rail {
    width: 6%;
}

.popupContent .table .tableHeader .tr .td.mamo,
.popupContent .table .tableBody .tr .td.mamo {
    width: 4%;
}

.popupContent .table .tableHeader .tr .td.date,
.popupContent .table .tableBody .tr .td.date {
    width: 7%;
}

.popupContent .table .tableHeader .tr .td.ton,
.popupContent .table .tableBody .tr .td.ton {
    width: 7%;
}

.popupContent .table .tableHeader .tr .td.value1,
.popupContent .table .tableBody .tr .td.value1 {
    width: 7%;
}

.popupContent .table.mgt .tableHeader .tr .td.value1,
.popupContent .table.mgt .tableBody .tr .td.value1 {
    width: 11%;
}

.popupContent .table .tableHeader .tr .td.value1_1,
.popupContent .table .tableBody .tr .td.value1_1 {
    width: 7%;
}

.popupContent .table .tableHeader .tr .td.value1_2,
.popupContent .table .tableBody .tr .td.value1_2 {
    width: 7%;
}

.popupContent .table .tableHeader .tr .td.value1_3,
.popupContent .table .tableBody .tr .td.value1_3 {
    width: 7%;
}

.popupContent .table .tableHeader .tr .td.value1_4,
.popupContent .table .tableBody .tr .td.value1_4 {
    width: 7%;
}

.popupContent .table .tableHeader .tr .td.value2,
.popupContent .table .tableBody .tr .td.value2 {
    width: 9%;
}

.popupContent .table .tableHeader .tr .td.value3,
.popupContent .table .tableBody .tr .td.value3 {
    width: 10%;
}

.popupContent .table .tableHeader .tr .td.value4,
.popupContent .table .tableBody .tr .td.value4 {
    width: 15%;
}

.popupContent .table .tableHeader .tr .td.value5,
.popupContent .table .tableBody .tr .td.value5 {
    width: 16%;
}

.popupContent .table .tableHeader .tr .td.value4.mgt,
.popupContent .table .tableBody .tr .td.value4.mgt {
    width: 19%;
}

.popupContent .table .tableHeader .tr .td.value5.mgt,
.popupContent .table .tableBody .tr .td.value5.mgt {
    width: 20%;
}

.table .tableHeader .tr .td .rowspan4{
    width: 400%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
}

.table .tableHeader .tr .td .rowspan5{
    width: 500%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
}


.table .tableHeader .tr .td .rowspan3{
    width: 300%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
}


/*== decision ==*/
.decisionPopupTitle{
    color: #EB501F;
    font-size: 25px;
    font-weight: bold;
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: 5px solid #9F9F9F;
    padding-bottom: 10px;
    padding-right: 80px;
    margin-left: 15px;
    margin-top: 15px;
    width: 25%;
}

.decisionPopupTitle img{
    width: 30px;
}

.decisionPopupTitle .closeBtn{
    position: absolute;
    right: 21px;
    cursor: pointer;
}

.decisionPopupContent{
    padding: 15px;
    /* width: 900px; */
    width: 1050px;
}

.decisionPopupContent .comment:first-child{
    margin-top: 50px;
}

.decisionPopupContent .comment{
    height: 115px;
}

.decisionPopupContent .comment .commentTitle{
    font-weight: bold;
    margin-bottom: 15px;
    display: flex;
    justify-content: left;
    align-items: center;
}

.decisionButtonContainer{
    width: calc(100% - 15px);
    display: flex;
    justify-content: end;
    padding-right: 15px;
}

.decisionButtonContainer .button{
    font-size: 13px;
    border-radius: 14px;
    background-color: #4854BF;
    color: white;
    width: fit-content;
    padding: 7px 13px;
    cursor: pointer;
}

.commentTitle .radioGroup{
    margin-left: 10px;
}

.wearMaintenance .dataBox{
    display: flex;
    justify-content: left;
    align-items: center;
}

.table .tableBody .tr .td.colspan2{
    border-bottom: 0px;
}

.table .tableBody .tr .td .colspan2{
    height: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
}

.searchContainer .componentBox .searchOption .trackSpeedFindClosest{
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
    font-size: 13px;
}

.searchContainer .componentBox .searchOption .trackSpeedFindClosest .line{
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchContainer .componentBox .searchOption .trackSpeedFindClosest .line .closestIcon{
    width: 7px;
    height: 7px;
    border-radius: 5px;
}

.searchContainer .componentBox .searchOption .acc{
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 13px;
}

.searchContainer .componentBox .searchOption .linear{
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
    font-size: 13px;
    width: 180px;
}

.searchContainer .componentBox .searchOption .linear .line{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 3px;
    width: 100%;
}

.searchContainer .componentBox .searchOption .linear .line .title{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-right: 5px;
}

.searchContainer .componentBox .searchOption .linear .line .info{
    width: calc(100% - 45px);
    font-size: 10px;
}

.wearMaintenance .containerTitle .selectKPInfo{
    left: 80px;
    position: absolute;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background: white;
    font-size: 13px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wearMaintenance .containerTitle .selectKPInfo .closestIcon{
    width: 14px;
    height: 5px;
    border-radius: 5px;
    margin-left: 17px;
}

.wearMaintenance .railStatusContainer .loading{
    position: absolute;
    top: 8px;
    border: 0;
    width: calc(100% - 0px);
    height: calc(100% - 20px);
    background: #00000094;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: white;
    z-index: 100;
    opacity: 0;
    animation: fadeIn 2s forwards;
}

.wearMaintenance .railStatusContainer .loading img{
    height: 40px;
    margin-right: 25px;
}
