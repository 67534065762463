.railStatusBox{
    width: 100%;
    height: 100%;
    position: relative;
}

.railStatusBox .obj{
    position: absolute;
    bottom: 25px;
}

.railStatusBox .rail{
    width: 100%;
    height: 6px;
    background: linear-gradient(0deg, rgba(56,119,176,1) 0%, rgba(113,168,218,1) 35%, rgba(142,186,226,1) 100%);
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    transform: skew(-20deg);
}

.railStatusBox .pointerList{
    width: 100%;
    bottom: 21px;
    height: 15px;
}

.railStatusBox .pointerList .obj{
    top: 2px;
    cursor: pointer;
}

.railStatusBox .pointer{
    height: 10px;
    background-color: white;
    border: 2px solid black;
    border-radius: 20px;
    position: absolute;
    cursor: pointer;
}

.railStatusBox .pointer.exist{
    background-color: #a9ff00;
}

.railStatusBox .pointer.selectedPath{
    background-color: rgb(255, 217, 0);
}

.railStatusBox .pointerList div.path{
    position: absolute;
    height: 6px;
    top: 5px;
}

.railStatusBox .path.exist{
    background-color: #a9ff00;
    border: 1px solid #000000;
}

.railStatusBox .path.exist div {
    position: absolute;
    top: 12px;
    right: 0;
    bottom: 0;
    left: 26px;
    height: 7px;
    pointer-events: none;
    font-size: 7px;
    width: 20px;
    height: 20px;
    /* background: #a9ff00; */
    border-radius: 4px;
    background-image: url(../../assets/icon/railstatus/KakaoTalk_20231026_151854255.png);
    background-position: center;
    background-size: contain;
}

.railStatusBox .pointer.exist div.cnt {
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 0;
    left: -4px;
    height: 7px;
    pointer-events: none;
    font-size: 7px;
    width: 20px;
    height: 20px;
    /* background: #a9ff00; */
    border-radius: 4px;
    background-image: url(../../assets/icon/railstatus/KakaoTalk_20231026_151854255.png);
    background-position: center;
    background-size: contain;
}

.railStatusBox .path.selectedPath{
    background: linear-gradient(to top, #990000 1%, #ff875c 99%);
}

.railStatusBox .pointer .pointerText{
    /* font-family: 'NotoSansKR-Regular'; */
    position: absolute;
    word-break: keep-all;
    transform: rotate(-52deg);
    bottom: 20px;
    text-align: left;
    width: 100%;
    left: -1px;
    top: -15px;
    font-size: 12px;
    font-weight: 500;
}