.routeSelection{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.routeSelection .routeLine{
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.routeSelection .routeLine .route{
    width: 400px;
    height: 300px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    box-shadow: 1px 1px 8px 1px #0000004d;
    background-position: center;
    color: white;
    text-shadow: 1px 1px black;
    background-size: 90%;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
}

.routeSelection .routeLine .route.img1{
    background-image: url(../../assets/logo/ictr.jpg);
}
.routeSelection .routeLine .route.img2{
    background-image: url(../../assets/logo/seoulmetro2.png);
}
.routeSelection .routeLine .route.img3{
    background-image: url(../../assets/logo/k_n_railway.png),
        url(../../assets/logo/k_r_r_institute.svg);
    background-position: 50% 28%, 50% 74%;
    background-size: 108%, 90%;
}
.routeSelection .routeLine .route.img4{
    background-image: url(../../assets/logo/korail.png);
}

.routeSelection .routeLine .route .text{
    position: absolute;
    left: 12px;
    top: 13px;
    font-size: 15px;
    background: #44546a;
    border-radius: 5px;
    padding: 6px;
}

.routeSelection .logo{
    width: 280px;
    margin-bottom: 25px;
}

.routeSelection .logo img{
    width: 100%;
}