.placeInfoBox{
    width: 100%;
    height: 100%;
    /* padding: 0px 10px 0px 10px; */
    position: relative;
}

.placeInfoBox canvas{
    width: 100%;
    height: 100%;
}

.placeInfoBox.pointer canvas{
    cursor: pointer;
}

.placeGaugeTooltip{
    position: absolute;
    left: 0;
    right: 0;
    width: 170px;
    height: auto;
    background-color: white;
    z-index: 100;
    border-radius: 5px;
    border: 1px solid #cccccc;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: 'NEO_R';
    padding-top: 10px;
    padding-bottom: 10px;
}

.placeGaugeTooltip .kp{
    margin-bottom: 7px;
}