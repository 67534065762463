.trackDeviation{
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 10px;
}

.trackDeviation .componentBox{
    padding: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 25px - 20px);
}

.measuringTemperatureHumidity .componentBox.chartBox{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.trackDeviation .componentBox .position{
    width: 300px;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-right: 5px;
    border-radius: 5px;
    border: 1px solid #cccccc;
}


.trackDeviation .contentBoxGroup{
    width: 100%;
    height: 238px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid #757575;
    border-radius: 5px;
    overflow: hidden;
    font-size: 13px;
}

.table .tableHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #d6d6d6a3;
}

.table .tableHeader .tr,
.table .tableBody .tr{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 24px;
}

.table2 .tableHeader .tr,
.table2 .tableBody .tr{
    height: 32px;
}

.table .tableBody{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.table .tableHeader .tr .td,
.table .tableBody .tr .td{
    height: 100%;
    border-right: 0.5px solid #cccccc;
    border-bottom: 0.5px solid #cccccc;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.table .tableHeader .tr .td.detail,
.table .tableBody .tr .td.detail{
    width: 110px;
}

.table .tableHeader .tr .td.point,
.table .tableBody .tr .td.point{
    width: 50px;
}

.table .tableHeader .tr .td.colspan2{
    border-bottom: 0;
}
.table .tableHeader .tr .td.rowspan2{
    border-right: 0;
}

.table .tableHeader .tr .td .colspan2{
    width: 100%;
    height: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
}

.table .tableHeader .tr .td .rowspan2{
    width: 200%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
}

.trackDeviation .optionBox .optionValue{
    width: calc(100% - 55px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding-left: 10px;
    padding-right: 10px;
}

.measuringTemperatureHumidity{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.measuringTemperatureHumidity .scroll{
    width: 100%;
    height: 100%;
    overflow: auto;
}

.measuringTemperatureHumidity .contentBox{
    width: calc(100% - 2px);
}

.measuringTemperatureHumidity .railStatusContainer{
    width: calc(100% - 1px);
}


.measuringTemperatureHumidity .selectPoints{
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.measuringTemperatureHumidity .selectPoints .point{
    font-size: 11px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background: white;
    padding: 4px;
    padding-left: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.measuringTemperatureHumidity .selectPoints .point img{
    width: 12px;
    height: 11px;
    margin-left: 5px;
    cursor: pointer;
}

.measuringTemperatureHumidity .componentBox.chartBox .loading{
    position: absolute;
    top: 0;
    border: 0;
    width: 100%;
    height: 100%;
    background: #00000094;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: white;
    opacity: 0;
    animation: fadeIn 2s forwards;
}

.measuringTemperatureHumidity .componentBox.chartBox .loading img{
    height: 40px;
    margin-right: 25px;
}

.measuringTemperatureHumidity .downloadCSV{
    width: 100px; margin-left: 10px;
}

.measuringTemperatureHumidity .dataOption.linear{
    min-width: 185px;
}