.trackDeviation{
    width: 100%;
    height: 100%;
}

.trackDeviation .componentBox{
    padding: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 25px - 20px);
}

.trackDeviation .componentBox.chartBox{
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
    overflow: auto;
    position: relative;
}

.trackDeviation .componentBox .position{
    width: 300px;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-right: 5px;
    border-radius: 5px;
    border: 1px solid #cccccc;
}


.trackDeviation .contentBoxGroup{
    width: 100%;
    height: 238px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trackDeviation .table{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 2px);
    border: 1px solid #757575;
    border-radius: 5px;
    overflow: hidden;
    font-size: 13px;
}

.table .tableHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #D6D6D6;
}

.table .tableHeader .tr,
.table .tableBody .tr{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 24px;
}

.table2 .tableHeader .tr,
.table2 .tableBody .tr{
    height: 32px;
}

.table4 .tableHeader .tr,
.table4 .tableBody .tr{
    height: 10px;
}

.table .tableBody{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.table .tableHeader .tr .td,
.table .tableBody .tr .td{
    height: 100%;
    border-right: 0.5px solid #cccccc;
    border-bottom: 0.5px solid #cccccc;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.table .tableHeader .tr .td.colspan2{
    border-bottom: 0;
}
.table .tableHeader .tr .td.rowspan2{
    border-right: 0;
}

.table .tableHeader .tr .td .colspan2{
    width: 100%;
    height: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
}

.table .tableHeader .tr .td .rowspan2{
    width: 200%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
}

.trackDeviation .optionBox .optionValue{
    width: calc(100% - 55px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding-left: 10px;
    padding-right: 10px;
}

/* trackReportPopup */
.trackReportPopupTitle{
    color: black;
    font-size: 25px;
    font-weight: bold;
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: 5px solid #9F9F9F;
    padding-bottom: 10px;
    padding-right: 80px;
    margin-left: 15px;
    margin-top: 15px;
    width: 25%;
}

.trackReportPopupTitle img{
    width: 30px;
}

.trackReportPopupTitle .closeBtn{
    position: absolute;
    right: 21px;
    cursor: pointer;
}

.trackReportPopupContent .table .tableHeader .tr .td,
.trackReportPopupContent .table .tableBody .tr .td{
    width: 100%;
}

.trackReportPopupContent{
    width: 900px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    position: relative;
}

.trackDeviation .chartMoveBtn{
    position: absolute;
    top: 50%;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid #cccccc;
    padding: 5px;
    background: white;
    z-index: 1;
    cursor: pointer;
    transition: 0.2s;
}

.trackDeviation .chartMoveBtn:hover{
    background-color: rgba(0, 81, 255, 0.664);
    color: white;
}

.trackDeviation .chartMoveBtn.left{
    left: 10px;
}

.trackDeviation .chartMoveBtn.right{
    right: 10px;
}

.trackDeviation .searchKPRange{
    position: absolute;
    right: 34px;
    top: 40px;
    z-index: 1;
    border: 1px solid #cccccc;
    padding: 5px;
    background: white;
    border-radius: 5px;
}

div.trackDeviationReportTable div.tableBody div.tr{
    height: 30px;
}

.table .tableBody .tr .td.target{
    color: blue;
}  
.table .tableBody .tr .td.caution{
    color: #ffca00;
}  
.table .tableBody .tr .td.repair{
    color: red;
}

.reportDownloadBtn{
    position: absolute;
    right: 12px;
    top: 18px;
    padding: 5px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background: #ffc84d;
    cursor: pointer;
    user-select: none;
}

.customTooltip{
    border: 1px solid #cccccc;
    background-color: white;
    padding: 5px 10px 10px 10px;
}

.customTooltip p {
    line-height: 0.6; /* 줄 간격을 글꼴 크기의 1.5배로 설정 */
  }