.railProfile {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 10px;
}

.railProfile .componentBox{
    padding: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 25px - 20px);
}


.railProfile .componentBox .radioButtons {
    width: 200px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.railProfile .componentBox .distanceSearch {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    height: 100%;
}

.railProfile .componentBox .distanceSearch input.local {
    border: none;
    border-bottom: 1px solid #cccccc;
    margin-right: 10px;
    margin-left: 10px;
    width: 70px;
    text-align: center;
    font-size: 17px;
    font-family: 'NEO_R';
}

.railProfile .componentBox .profile {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.railProfile .componentBox .profile .accData {
    position: absolute;
    left: 10px;
    top: 95px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 0px;
}

.railProfile .componentBox .profile .accData .title {
    padding: 5px;
    background: #cccccc94;
}

.railProfile .componentBox .profile .accData .value{
    padding: 5px;
}

.railProfile .componentBox .profile .railTitle{
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 15px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 5px;
}

.railProfile .componentBox .profile:first-child{
    margin-left: 0;
    margin-right: 10px;
}

.railProfile .componentBox .profile:last-child{
    margin-right: 0;
    margin-left: 10px;
}

.profileSlider{
    width: calc( 100% - 30px);
    height: calc( 100% - 30px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #cccccc;
    border-radius: 5px;
    /* padding: 20px; */
    /* margin-bottom: 10px; */
    position: relative;
    margin-right: 5px;
    padding: 15px;
}

.profileSlider img,
.profileSlider .profileImg{
    width: 60%;
    height: calc( 100% - 15px);
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #cccccc; */
    border-radius: 5px;
    /* margin-bottom: 45px;*/
}

.profileData{
    width: 100%;
    height: calc(100% - 260px);
}

.profileData .picture{
    width: 100%;
    height: 160px;
    margin-bottom: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    position: relative;
}

.profileData .picture img{
    height: 100%;
}

.profileData .picture .pictureData{
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 7px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background: white;
    padding: 3px;
    z-index: 1;
}

.profileData .picture .regDate{

}

.profileData .picture .newUpload{
    left: 65px;
    background-color: #ff8d00;
    color: white;
}

.table .tableHeader .tr .td.colspan3{
    border-bottom: 0;
}
.table .tableHeader .tr .td .colspan3{
    width: 100%;
    height: 300%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
}

.table .tableHeader .tr .td.rowspan7{
    border-right: 0;
}

.table .tableHeader .tr .td .rowspan7{
    width: 700%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
}

.table .tableHeader .tr .td.measurementDate,
.table .tableBody .tr .td.measurementDate{
    width: 20%;
}

.table .tableHeader .tr .td.mamo,
.table .tableBody .tr .td.mamo{
    width: 12%;
}

.table .tableHeader .tr .td.ton,
.table .tableBody .tr .td.ton{
    width: 18%;
}

.railProfile .table{
    font-size: 15px;
    height: auto;
}

.imageViewButton{
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 5px;
    border: 1px solid #000000;
    padding: 5px;
    background: #a7cfff;
    color: black;
    cursor: pointer;
    font-size: 10px;
}


.profileSlider .picture{
    width: 300px;
    height: 160px;
    margin-bottom: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10000;
    background: white;
}

.profileSlider .picture img{
    height: 100%;
    margin-bottom: 0;
}

.profileSlider .picture .pictureData{
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background: white;
    padding: 3px;
    z-index: 1;
    cursor: pointer;
}

.profileSlider .picture .regDate{

}

.profileSlider .picture .newUpload{
    left: 65px;
    background-color: #ff8d00;
    color: white;
}


.profileSlider .picture .closeImg{
    left: 75%;
    width: 50px;
}

.datafinder .dataList.header{
    width: 135px;
}

.monitoringContainer .datafinder.header .dataList .line .dataName {
    width: 120px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.monitoringContainer .datafinder .dataList.header .line{
    width: 135px;
}

.datafinder .scroll{
    overflow: auto;
    height: 100%;
}

.contentBox .profile .pictureContainer{
    width: 100%;
    height: 250px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentBox .profile .pictureContainer .detailImgs{
    width: 100%;
    height: 100%;
    margin-left: 5px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-direction: column;
    overflow-y: auto;
}

.contentBox .profile .pictureContainer .detailImgs .imageContainer{
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentBox .profile .pictureContainer .detailImgs img{
    width: 85%;
    height: 85%;
    border-radius: 3px;
}

.contentBox .profile .pictureContainer .detailImgs .title{
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
}

.contentBox .profile .pictureContainer .detailImgs .uploadBtn{
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 5px;
    background-color: #ff8100;
    color: white;
    font-weight: bold;
    cursor: pointer;
    z-index: 1;
}

.profileData .table .tableBody .tr{
    height: 40px;
}