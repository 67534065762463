.speedBoxContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.speedBoxContainer .legendBox{
    /* width: 150px; */
    height: 10px;
    position: absolute;
    left: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.speedBoxContainer .legendBox .option{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
}

.speedBoxContainer .legendBox .colorBar{
    width: 20px;
    height: 5px;
    margin-right: 5px;
}

.speedBoxContainer .xaxis{
    width: 70px;
    height: 100%;
    font-size: 12px;
    font-weight: bold;
    position: relative;
}

.speedBoxContainer .xaxis .line{
    position: absolute;
    left: 30px;
    /* width: 40px; */
    padding: 0px;
    border: 1px solid #cccccc70;
    background: #ffffff91;
    border-radius: 5px;
}

.speedBoxContainer .xaxis .line.unit{
    font-size: 8px;
}

.trackSpeedBox{
    width: 100%;
    height: 100%;
}

.trackSpeedBox canvas{
    width: 100%;
    height: 100%;
}