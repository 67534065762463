@font-face {
  font-family: 'NEO_B' ;
  src: url('./assets/fonts/NanumSquareNeo/OTF/NanumSquareNeoOTF-Bd.otf');
}
@font-face {
  font-family: 'NEO_E' ;
  src: url('./assets/fonts/NanumSquareNeo/OTF/NanumSquareNeoOTF-Eb.otf');
}
@font-face {
  font-family: 'NEO_H' ;
  src: url('./assets/fonts/NanumSquareNeo/OTF/NanumSquareNeoOTF-Hv.otf');
}
@font-face {
  font-family: 'NEO_L' ;
  src: url('./assets/fonts/NanumSquareNeo/OTF/NanumSquareNeoOTF-Lt.otf');
}
@font-face {
  font-family: 'NEO_R' ;
  src: url('./assets/fonts/NanumSquareNeo/OTF/NanumSquareNeoOTF-Rg.otf');
}


body {
  margin: 0;
  font-family: 'NEO_R', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f12b;
  /* border-top: 2px solid #d5d5d5; */
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
span{
  font-family: 'NEO_R' !important;
}

.emptyBox{
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 5px;
  border: 1px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url(./assets/icon/empty/empty5.png);
  background-position: 50% 29%;
  background-repeat: no-repeat;
  background-size: 10%; */
  flex-direction: column;
  background-color: #f9f9f9;
  /* padding-top: 30px; */
}

.emptyBox div{
  color: #717171;
}

.emptyBox img{
  height: 150px;
}

.table.table2::-webkit-scrollbar-track,
.table.table4::-webkit-scrollbar-track {
  background-color: #f1f1f12b;
  border-top: 1px solid #d5d5d5;
}

/* custome button */
button.search{
  width: 50px;
  height: 35px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  font-weight: bold;
  color: black;
  border-radius: 5px;
  font-family: 'NEO_R';
  cursor: pointer;
}

/* date Option */
.dataOption .track{
  min-width: 140px;
}

.dataOption .date{
  min-width: 125px;
}

.dataOption .date .alertText{
  position: absolute;
  top: -15px;
  color: #00000059;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}