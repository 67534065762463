.railTrackAlignment .componentBox .radioButtons {
    width: 200px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.componentBox .trackBox{
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.componentBox .chartBox{
    width: 100%;
    height: calc(100% - 2px);
    position: relative;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-left: 10px;
}

.componentBox .curLine{
    position: absolute;
    width: 1px;
    height: 96%;
    background-color: red;
    left: 50%;
}

.decisionPopupContent .chartConatiner{
    width: 100%;
    height: 300px;
    padding-top: 15px;
}

.railTrackAlignment .optionBox.position .optionValue{
    height: 100%;
}

.railRoughness .componentBox.chartBox{
    position: relative;
}

.railRoughness .componentBox.chartBox .loading{
    position: absolute;
    top: 10px;
    border: 0;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    background: #00000094;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: white;
    z-index: 1;
    opacity: 0;
    animation: fadeIn 2s forwards;
}

.railRoughness .componentBox.chartBox .loading img{
    height: 40px;
    margin-right: 25px;
}

/*== decision ==*/
.railRoughness .decisionPopupTitle{
    color: #EB501F;
    font-size: 25px;
    font-weight: bold;
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: 5px solid #9F9F9F;
    padding-bottom: 10px;
    padding-right: 80px;
    margin-left: 15px;
    margin-top: 15px;
    width: 30%;
}

.railRoughness .decisionPopupContent{
    width: 660px;
}

.railRoughness .decisionPopupContent .comment{
    height: 150px;
}

.railRoughness .decisionPopupContent .header{
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 20px;
}

.railRoughness .decisionPopupContent .popupContent{
    display: flex;
    justify-content: left;
    align-items: center;
}

.railRoughness .decisionPopupContent .popupContent .contentSection{
    margin-left: 10px;
    margin-right: 10px;
}

.railRoughness .decisionPopupContent .popupContent .contentSection .contentTitle{
    font-size: 15px;
    margin-bottom: 10px;
}

.railRoughness .decisionPopupContent .popupContent .contentSection .contentValue{
    font-size: 13px;
    height: 35px;
    padding-left: 10px;
}

.railRoughness .decisionPopupContent .popupContent .leftContent{

}

.railRoughness .decisionPopupContent .popupContent .rightContent{
}

.contentBox .componentBox.chartBox .addData{
    position: absolute;
    right: 20px;
    top: 20px;
    width: 210px;
    /* height: 100px; */
    background-color: white;
    border-radius: 5px;
    border: 1px solid #cccccc;
    z-index: 1;
    font-size: 12px;
}

.contentBox .componentBox.chartBox .addData .dataLines{
    border-bottom: 1px solid #cccccc;
}

.contentBox .componentBox.chartBox .addData .dataLines .title{
    padding-top: 5px;
    padding-bottom: 5px;
    background: #e3e3e3;
    margin-bottom: 5px;
}

.contentBox .componentBox.chartBox .addData .dataLines .line{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.contentBox .componentBox.chartBox .addData .dataLines .line .lineName{
    width: 100px;
}

.contentBox .componentBox.chartBox .addData .dataLines .line .lineVal{
    width: calc( 100% - 100px);
}

.lwd .selectPoints{
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.lwd .selectPoints .point{
    font-size: 11px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background: white;
    padding: 4px;
    padding-left: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lwd .selectPoints .point img{
    width: 12px;
    height: 11px;
    margin-left: 5px;
    cursor: pointer;
}


.lwd .chartMoveBtn{
    position: absolute;
    top: 50%;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid #cccccc;
    padding: 5px;
    background: white;
    z-index: 1;
    cursor: pointer;
    transition: 0.2s;
}

.lwd .chartMoveBtn:hover{
    background-color: rgba(0, 81, 255, 0.664);
    color: white;
}
